<template>
  <b-overlay
    :show="showLoading"
    variant="dark"
    :opacity="0.25"
    rounded="sm"
  >
    <b-card
      no-body
      class="p-3"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer ref="formRef">
            <b-form @submit.prevent>
  
              <b-row>
  
                <!-- Code Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashAdvance.singular.code')"
                    label-for="code"
                  >
                    <b-form-input
                      id="code"
                      size="sm"
                      :placeholder="$t('apps.transactionsCashAdvance.placeholder.code')"
                      v-model="form.code"
                      :disabled="actions.isPreview"
                    />
                  </b-form-group>
                </b-col>
  
                <!-- Date Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashAdvance.singular.date')"
                    label-for="date"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date"
                      rules="required"
                    >
                      <flat-pickr
                        id="date"
                        :config="dateFormat"
                        v-model="form.date"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        class="form-control form-control-sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
  
                <!-- Account Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashAdvance.singular.cashBankCode')"
                    label-for="account-id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="account"
                      rules="required"
                    >
                      <v-select
                        v-if="companyActive.plan_id > 2"
                        id="cashbank-account"
                        ref="firstFocusSelectInput"
                        v-model="form.cashbankId"
                        :options="LOV.cashbankCodeAccounts"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <v-select
                        v-else
                        id="account-id"
                        class="select-size-sm"
                        v-model="form.accountId"
                        :options="LOV.accounts"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
  
              </b-row>
  
              <b-row>
                <!-- Cash Advance Request Search -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashAdvance.singular.cashAdvanceRequest')"
                    label-for="cashAdvanceRequestId"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cashAdvanceRequest"
                      rules="required"
                    >
                      <!-- <b-form-input
                        id="cash-advance-request-id"
                        size="sm"
                        v-model="addForm.cashAdvanceRequestCode"
                        :disabled="actions.isPreview"
                      /> -->
                      <v-select
                        id="cash-advance-request-id"
                        class="select-size-sm"
                        v-model="addForm.cashAdvanceRequestCode"
                        :options="LOV.cashAdvanceRequest"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        @option:selected="handleSelectedRequest"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ (customError.cashAdvanceRequestCode) ? customError.cashAdvanceRequestCode : errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Tags Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashbank.singular.tags')"
                    label-for="tags"
                  >
                    <v-select
                      v-model="form.tags"
                      id="tags"
                      label="name"
                      multiple
                      :options="LOV.tags"
                      :reduce="field => field.id"
                      :searchable="!actions.isPreview"
                      :selectable="option => !actions.isPreview"
                    />
                  </b-form-group>
                </b-col>
  
              </b-row>
              <b-row>
                <!-- Description Field -->
                <b-col cols="12" md="8">
                  <b-form-group
                    :label="$t('apps.transactionsCashAdvance.singular.notes')"
                    label-for="notes"
                  >
                    <b-form-textarea
                      id="notes"
                      size="sm"
                      v-model="form.description"
                      :disabled="actions.isPreview"
                    />
                  </b-form-group>
                </b-col>
  
              </b-row>
  
              <b-row v-if="isRequestExist">
                <b-col cols="12" md="6" class="ml-xl-n1">
                  <b-table-simple responsive caption-top borderless class="font-medium-1">
                    <caption class="ml-1 font-weight-bold">Detail Information:
                      <hr>
                    </caption>
                    <b-tbody>
                      <b-tr>
                        <b-td sticky-column-left class="pr-1 align-top">{{ $t('apps.transactionsCashAdvance.singular.employee') }}</b-td>
                        <b-td class="px-0 align-top">:</b-td>
                        <b-td class="pl-1 align-top">{{ addForm.employeeName }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td sticky-column-left class="pr-1 align-top">{{ $t('apps.transactionsCashAdvance.singular.type') }}</b-td>
                        <b-td class="px-0 align-top">:</b-td>
                        <b-td class="pl-1 align-top">{{ addForm.typeName }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td sticky-column-left class="pr-1 align-top">{{ $t('apps.transactionsCashAdvance.singular.amount') }}</b-td>
                        <b-td class="px-0 align-top">:</b-td>
                        <b-td class="pl-1 align-top">{{ addForm.amount }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td sticky-column-left class="pr-1 align-top">{{ $t('apps.transactionsCashAdvance.singular.description') }}</b-td>
                        <b-td class="px-0 align-top">:</b-td>
                        <b-td class="pl-1 align-top">{{ addForm.description }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
  
              <div class="d-flex flex-sm-column justify-content-between" v-if="paramsId != null">
                <b-table
                  id="refFormTable"
                  ref="refFormTable"
                  fixed
                  no-provider-paging
                  no-provider-filtering
                  :fields="tableColumns"
                  :items="listTitle.details"
                  primary-key="id"
                  show-empty
                  class="position-relative mt-2"
                >
                  <template #cell(type)="{ item }">
                    <span class="font-weight-bold d-block text-nowrap" v-if="item.transaction_type_code == 'RL' ">
                      Realisasi
                    </span>
                    <span class="font-weight-bold d-block text-nowrap" v-else>
                      Refund
                    </span>
                  </template>
  
                  <template #cell(amount)="{ item }">
                    <span class="font-weight-bold d-block text-nowrap">
                      {{ formatCurrency(item.credit_amount) }}
                    </span>
                  </template>
  
                  <template #cell(action)="{ item }">
                    <b-button
                      class="mb-sm-1 mb-md-0"
                      variant="outline-danger"
                      block
                      @click="confirmDeleteData(item.code, item.transaction_type_code, item.credit_amount)"
                    >
                      <feather-icon icon="Trash2Icon" />
                      {{ $t('globalActions.delete') }}
                    </b-button>
                  </template>
  
                </b-table>
              </div>
  
              <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
                <b-button
                  class="mb-sm-1 mb-md-0"
                  :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                  :to="{ name: 'apps-transactions-cash-advance-list'}"
                >
                  <feather-icon icon="ArrowLeftIcon" />
                  {{ $t('globalActions.backToList') }}
                </b-button>
  
                <b-button
                  v-if="actions.isPreview"
                  class=" mb-sm-1 mb-md-0"
                  variant="outline-danger"
                  @click="handleDelete"
                >
                  <feather-icon icon="Trash2Icon" />
                  {{ $t('globalActions.delete') }}
                </b-button>
  
                <b-button
                  v-if="!actions.isPreview"
                  variant="primary"
                  type="submit"
                  @click="handleSubmit"
                >
                  <feather-icon icon="SaveIcon" />
                  {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
                </b-button>
  
  
                <b-button
                  v-if="actions.isPreview"
                  variant="outline-success"
                  :to="{ path: `/transactions/cash-advance/refund/detail/${this.paramsId}`}"
                >
                  <feather-icon icon="EditIcon" />
                  {{ $t('apps.transactionsCashAdvance.actions.refund') }}
                </b-button>
  
                <b-button
                  v-if="actions.isPreview"
                  variant="outline-primary"
                  :to="{ path: `/transactions/cash-advance/realisation/detail/${this.paramsId}`}"
                >
                  <feather-icon icon="EditIcon" />
                  {{ $t('apps.transactionsCashAdvance.actions.realisation') }}
                </b-button>
  
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BRow, BTableSimple, BTbody, BTd, BTr, BTable, BOverlay} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onMounted, ref} from '@vue/composition-api'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import {required} from '@validations'
import { formatCurrency, formatDatepicker} from '@/utils/formatter'

import useFormResource from '@/comp-functions/useFormResource'
import usePushTags from '@/comp-functions/utils/usePushTags'
import storestate from '@/store/index'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BTable,
    BOverlay,
  },


  setup () {
    const {
      paramsId,
      formRef,
      actions,
      changeToEdit,
      get,
      store,
      destroy
    } = useFormResource({
      url: 'transaction/cash-advance',
      localeContextPath: 'apps.transactionsCashAdvance.singular.cashAdvance',
      redirectPathName: 'apps-transactions-cash-advance'
    })

    const form = ref({
      code: '',
      date: formatDatepicker(Date.now()),
      cashbankId: '',
      cashAdvanceRequestId: '',
      accountId: '',
      employeeId: '',
      description: '',
      typeCode: '',
      amount: '',
      tags: []
    })
    const showLoading = ref(false)

    const tableColumns = [
      { key: 'code', label: 'Code' },
      { key: 'type', label: 'Type' },
      { key: 'date', label: 'Date' },
      { key: 'amount', label: 'Amount' },
      { key: 'action', label: 'Action' },
    ]

    const listTitle = ref({
      details: [
      ]
    })

    const addForm = ref({
      cashAdvanceRequestCode: '',
      employeeName: '-',
      typeName: '-',
      amount: '',
      description: ''
    })

    const isRequestExist = ref(false)

    const LOV = ref({
      accounts: [],
      cashbankCodeAccounts: [],
      tags: [],
      cashAdvanceRequest: []
    })

    const customError = ref({
      cashAdvanceRequestCode: null
    })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true
    })

    const companyActive = storestate.state.user.currentCompany
    const dateFormat = ref({
      dateFormat: 'd/m/Y',
      allowInput: true,
      disable: [
        function (date) {
          return (date.getTime() < (new Date(companyActive.closing_year,  companyActive.closing_month, 1).getTime()))
        }
      ]
    })

    const getAccounts = async () => {
      LOV.value.accounts = await get({ url: 'master/accounts/child?account_subtype_code=CASHBANK'})
    }

    const getDetailTransaction = async () => {
      listTitle.value.details = await get({ url: `value/cash-advance-details/${paramsId}`})
    }

    const getCashbankCode = async () => {
      LOV.value.cashbankCodeAccounts = await get({ url: 'value/mapping-cashbank-per-users'})
    }

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    const getCashAdvanceRequest = async () => {
      LOV.value.cashAdvanceRequest = await get({ url: 'transaction/cash-advance/requests'})
    }

    const handleSelectedRequest = val => {
      const approvedAmount = parseFloat(val.approved_amount)
      addForm.value.cashAdvanceRequestCode = val.code
      addForm.value.employeeName = val.employee_name
      addForm.value.typeName = val.type_name
      addForm.value.description = val.description

      addForm.value.amount = formatCurrency(approvedAmount)

      form.value.cashAdvanceRequestId = val.id
      form.value.employeeId = val.employee_id
      form.value.typeCode = val.type_code
      form.value.amount = approvedAmount

      isRequestExist.value = true
    }

    let deletes = ref()

    const deleteData = async (code, type, amount) => {
      const params = [
        `journal_code=${code}`,
        `cash_advance_code=${form.value.code}`,
        `type=${type}`,
        `amount=${parseFloat(amount)}`
      ]

      deletes.value = await get({ url: `transaction/cash-advances/remove?${ params.join('&') }`})
      await getDetailTransaction()
    }


    onMounted(() => {
      getAccounts()
      if (companyActive.plan_id > 2) {
        getCashbankCode()
      }
      getTags()
      getCashAdvanceRequest()
    })


    const { pushTagsToAPI } = usePushTags()
    const pushTags = async newTag => {
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = await pushTagsToAPI(newTag)
    }


    return {
      deleteData,
      companyActive,
      form,
      showLoading,
      numeric,
      paramsId,
      formRef,
      get,
      store,
      destroy,
      actions,
      changeToEdit,
      LOV,
      required,
      customError,
      addForm,
      isRequestExist,
      dateFormat,
      pushTags,
      handleSelectedRequest,
      tableColumns,
      listTitle,
      getDetailTransaction,
      formatCurrency,
    }
  },


  data() {
    return {
      data: null
    }
  },


  async mounted () {
    if (this.paramsId) {
      this.showLoading = true
      this.actions.isPreview = true
      await this.getDataPreview()
      await this.getDetailTransaction()
      this.showLoading = false
    }
  },


  methods: {
    getTotalDetailAmount () {
      let total = 0
      this.data.details.map((detail) => {
        total += detail.amount
      })

      return total
    },


    async getDataPreview () {
      this.data = await this.get({
        url: `transaction/cash-advance/${this.paramsId}`
      })

      // update data according to the number of data post
      for (const key in this.data) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.data.hasOwnProperty(key)) {
          this.form[key] = this.data[key]
        }
      }

      this.form.accountId = this.data.cashBank ? this.data.cashBank.account_id : ''
      this.form.cashbankId = this.data.cashbank_id
      this.form.tags = this.data.tags.map(tag => parseInt(tag))
      this.addForm.cashAdvanceRequestCode = this.data.cashAdvanceRequest.code
      this.addForm.employeeName = this.data.employee.pic_name
      this.addForm.typeName = this.data.type.name
      this.addForm.description = this.data.cashAdvanceRequest.description
      this.addForm.amount = formatCurrency(this.getTotalDetailAmount())
      this.isRequestExist = true
    },


    async confirmDeleteData(code, type, amount) {
      this.$swal({
        title: `${this.$t('confirm.deleteThis')} cash advance activity?`,
        text: this.$t('info.notAbleRecover'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-flat-secondary mb-sm-1 mb-md-0',
          actions: 'd-flex flex-sm-column flex-md-row justify-content-around'
        },
        confirmButtonText: this.$t('globalActions.deleteButton'),
        cancelButtonText: this.$t('globalActions.cancelDeleteButton')
      })
        .then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            this.showLoading = true
            await this.deleteData(code, type, amount)
            this.showLoading = false
          }
        })
    },


    async handleDelete () {
      this.showLoading = true
      await this.destroy(this.$swal)
      this.showLoading = false
    },


    async handleSubmit () {
      this.showLoading = true
      await this.store({
        $swal: this.$swal,
        data: this.form
      })
      this.showLoading = false
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
